import React from "react";
import {OpLogListRequest, OpLogListResponse} from "../../../../../api/oplogs";
import NoItemsMessage from "../../../../Table/NoItemsMessage";
import {useOpLogsDesktopTable} from "../../Hooks/Desktop/useOpLogsDesktopTable";
import {LogTypeFilters} from "../../../../../store/oplog/actions/OpLogActionTypes";
import SupplementalLogListDesktopContainer from "../../../../SupplementalLogs/List/Desktop/SupplementalLogListDesktopContainer";
import PulseExpandingTableWithServerPagination from "../../../../../modules/Table/PulseExpandingTableWithServerPagination";
import {pagedRequestConfig} from "../../../../Filters/helpers/filterHelpers";
import {useOpenLogRows} from "../../Hooks/Shared/useOpenLogRows";
import {useOpLogBaseFilters} from "../../Hooks/Shared/useOpLogBaseFilters";
import FilterContainer from "../../../../Filters/FilterContainer";
import OpLogsTableFilters from "../Shared/OpLogsTableFilters";
import OpLogFormContainer from "../../../../OpLog/OpLogFormContainer";
import TableActionContainer from "../../../../Table/TableActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {useSharedOpLogTable} from "../../Hooks/Shared/useSharedOpLogTable";

function OpLogsDesktopTable({logType, response}: OpLogTableProps) {
    const {items, headers} = useOpLogsDesktopTable({
        logType,
        response
    });
    const {updateSearchStrings} = useOpLogBaseFilters();
    const {openRows} = useOpenLogRows(logType);
    const {onRequestChanged, request, refreshData} = useSharedOpLogTable();

    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <OpLogsTableFilters
                    onRequestChanged={(req) => onRequestChanged(req)}
                    logType={logType}
                />
            </FilterContainer>

            <OpLogFormContainer logType={logType} />
            {request && (
                <div className="pt-3">
                    <TableActionContainer>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Refresh"}
                            onClick={() => {
                                if (!request) return;
                                refreshData(request);
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    </TableActionContainer>
                    <PulseExpandingTableWithServerPagination
                        resultsPerPage={pagedRequestConfig.resultsPerPage}
                        totalResults={response.totalResults}
                        initialPageNumber={request?.pageNum}
                        onPageChanged={(newPageNum) => {
                            if (!request) return;
                            const newRequest: OpLogListRequest = {
                                ...request,
                                pageNum: newPageNum
                            };
                            onRequestChanged(newRequest);
                            updateSearchStrings(newRequest);
                        }}
                        // @ts-ignore
                        items={items}
                        headers={headers}
                        noItemsSection={
                            <NoItemsMessage
                                message={"There are no Operational Logs matching the criteria"}
                            />
                        }
                        expandedRow={{
                            viewMoreRenderedItem: (item) => (
                                <SupplementalLogListDesktopContainer id={+item.id} />
                            )
                        }}
                        initialExpandedRows={openRows}
                        viewActionText={"Supplemental Logs"}
                    />
                </div>
            )}
        </React.Fragment>
    );
}

export default OpLogsDesktopTable;

export interface OpLogTableProps {
    logType: LogTypeFilters;
    response: OpLogListResponse;
}
