import React, {useEffect} from "react";
import LogSelection from "./Components/Shared/LogSelection";
import {useDispatch} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../Navigation/routeNames";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const OperationalLogsSelector = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.logListSelector.name,
                path: routeNames.logListSelector.path
            }
        ];

        dispatch(setNavigationItems(navItems));
    }, []);
    return (
        <React.Fragment>
            <TopBar titleText="Operational Logs" />
            <div className="page-container pt-3">
                <LogSelection />
            </div>
        </React.Fragment>
    );
};

export default OperationalLogsSelector;
