import React from "react";
import {OpLogListRequest, OpLogListResponse} from "../../../../../api/oplogs";
import {LogTypeFilters} from "../../../../../store/oplog/actions/OpLogActionTypes";
import {useOpenLogRows} from "../../Hooks/Shared/useOpenLogRows";
import {useOpLogBaseFilters} from "../../Hooks/Shared/useOpLogBaseFilters";
import FilterContainer from "../../../../Filters/FilterContainer";
import OpLogsTableFilters from "../Shared/OpLogsTableFilters";
import OpLogFormContainer from "../../../../OpLog/OpLogFormContainer";
import TableActionContainer from "../../../../Table/TableActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {useSharedOpLogTable} from "../../Hooks/Shared/useSharedOpLogTable";
import MobileRowItemContainer from "./MobileRowItemContainer";
import SupplementalLogListMobileContainer from "../../../../SupplementalLogs/List/Mobile/SupplementalLogListMobileContainer";
import NoItemsMessage from "../../../../Table/NoItemsMessage";
import OpLogMobilePagination from "./OpLogMobilePagination";
import {pagedRequestConfig} from "../../../../Filters/helpers/filterHelpers";

function OpLogsMobileTable({logType, response}: OpLogTableProps) {
    const {updateSearchStrings} = useOpLogBaseFilters();
    const {openRows} = useOpenLogRows(logType);
    const {onRequestChanged, request, refreshData} = useSharedOpLogTable();

    return (
        <React.Fragment>
            <FilterContainer closed={true}>
                <OpLogsTableFilters
                    onRequestChanged={(req) => onRequestChanged(req)}
                    logType={logType}
                />
            </FilterContainer>

            <OpLogFormContainer logType={logType} />
            {request && (
                <div className="pt-3">
                    <TableActionContainer>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Refresh"}
                            onClick={() => {
                                if (!request) return;
                                refreshData(request);
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    </TableActionContainer>
                    {response.logs.length > 0 ? (
                        <React.Fragment>
                            {response.logs.map((log, index) => {
                                return (
                                    <MobileRowItemContainer
                                        key={index}
                                        log={log}
                                        initialExpandedRows={openRows}
                                        expandedRow={{
                                            viewMoreRenderedItem: (item) => (
                                                <SupplementalLogListMobileContainer id={+item.id} />
                                            )
                                        }}
                                    />
                                );
                            })}
                            <OpLogMobilePagination
                                resultsPerPage={pagedRequestConfig.resultsPerPage}
                                totalResults={response.totalResults}
                                initialPageNumber={request?.pageNum}
                                onPageChanged={(newPageNum) => {
                                    if (!request) return;
                                    const newRequest: OpLogListRequest = {
                                        ...request,
                                        pageNum: newPageNum
                                    };
                                    onRequestChanged(newRequest);
                                    updateSearchStrings(newRequest);
                                }}
                            />
                        </React.Fragment>
                    ) : (
                        <NoItemsMessage message={"There are no logs matching the filters"} />
                    )}
                </div>
            )}
        </React.Fragment>
    );
}

export default OpLogsMobileTable;

export interface OpLogTableProps {
    logType: LogTypeFilters;
    response: OpLogListResponse;
}
