import React, {useEffect} from "react";
import {OpLogComplete} from "../../../api/oplogs";
import {useEditOperationalLogsForm} from "../Hooks/useEditOperationalLogsForm";
import FormRow from "../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import CharacterLimitWarning from "../../Form/CharacterLimitWarning";
import {useRenderLogTypeForm} from "../Hooks/useRenderLogTypeForm";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import FormActionContainer from "../../Form/FormActionContainer";
import {nullifyOpLogStore} from "../../../store/oplog/actions/OpLogActions";
import {useDispatch} from "react-redux";
import ButtonWithConfirmation from "../../Button/ButtonWithConfirmation";

function EditOperationalLogForm({log}: OpLogComplete) {
    const {updateOperationLogs, save, clearForm} = useEditOperationalLogsForm();
    const {renderForm} = useRenderLogTypeForm();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(nullifyOpLogStore());
        };
    }, []);
    return (
        <React.Fragment>
            {renderForm(log, updateOperationLogs, log.type)}
            <FormRow rowName={"Remarks"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields text-area-inputs"}
                    element={"textarea"}
                    onChange={(event) => {
                        const {value} = event.target;
                        updateOperationLogs({
                            ...log,
                            remarks: value
                        });
                    }}
                    disabled={false}
                    value={log.remarks}
                    placeholder="Enter remarks here..."
                />
                <CharacterLimitWarning characterLimit={10000} textValue={log.remarks} />
            </FormRow>
            <div className="row-modifier">
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={async () => await save(log)}
                        roundedCorner
                        colour={ButtonColourOptions.Yellow}
                    />
                    <ButtonWithConfirmation
                        buttonText={"Clear"}
                        action={() => clearForm(log, true)}
                        actionButtonColour={ButtonColourOptions.DarkBlue}
                        message={"Are you sure you want to clear this log entry?"}
                    />
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
}

export default EditOperationalLogForm;
